<template>
    <div>
        <!--
        <span v-if="company_name" class="label-local">Empresa: </span>
        <span v-if="company_name" class="storehouse-breadcrumb breadcrumb-company">
            <b>{{ company_name }}</b>
        </span>
        <br v-if="company_name">
        
        <span v-if="sede_name" class="label-local">Sede: </span>
        <span v-if="sede_name" class="storehouse-breadcrumb breadcrumb-sede">
            <b>{{ sede_name }}</b>
        </span>
        <br v-if="sede_name">
        -->

        <span v-if="office_name != 'Principal'" class="label-local">Sucursal: </span>
        <span class="storehouse-breadcrumb breadcrumb-office">
            <b>{{ office_name }}</b>
        </span>
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name: 'StorehouseBreadcrumb',
    computed: {
        ...mapState('inventories', [
            'office_id',
            'office_name',
            'sede_name',
            'company_name'
        ])
    }

}
</script>

<style scoped>
.label-local{
    display: inline-block;
    width: 90px;
    text-transform: uppercase;
    font-size: .9rem;
}
</style>
<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="7" class="pt-1">
                    <span class="headline font-weight-medium">Lista de imeis</span>
                    <br>
                    <storehouse-breadcrumb></storehouse-breadcrumb> 
                </v-col>
                <v-col cols="5">
                    <span class="title font-weight-medium text-uppercase float-right">
                        ({{ inventory.description }})
                    </span>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="list_imeis"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="55vh"
                        >

                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todos los imeis
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.status="{item}">
                                <span :class="'imei-status ' + item.status">{{ item.status }}</span>
                            </template>

                            <template v-slot:item.condition="{item}">
                                <span :class="'imei-status imei-' + item.condition">{{ item.condition }}</span>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

        </template>
    </v-container>
</template>

<script>

import {mapState} from 'vuex'

import StorehouseBreadcrumb from '../../../components/storehouses/StorehouseBreadcrumb'

export default {
    name: 'Detail',
    components: {
        StorehouseBreadcrumb
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Logística',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Almacenes',
                    disabled: false,
                    href: '/app/logistica/almacenes',
                }
            ],
            headers: [
                { text: 'PROVEEDOR', value: 'supplier_name' },
                { text: 'CATEGORIA', value: 'category' },
                { text: 'MARCA', value: 'brand' },
                { text: 'MODELO', value: 'type' },
                { text: 'CAPACIDAD', value: 'capacity' },
                { text: 'COLOR', value: 'color' },
                { text: 'IMEI', value: 'imei_1' },
                { text: 'ESTADO', value: 'status' },
                { text: 'CONDICIÓN', value: 'condition' },
            ],
        }
    },
    computed: {
        ...mapState('inventories', [
            'inventory',
            'list_imeis',
            'office_name',
            'sede_name',
            'company_name'
        ])
    }
}
</script>

<style scoped>

</style>